/* src/Terminal.css */
.terminal {
    background-color: #1e1e1e;
    color: #a5a5a5;
    padding: 10px;
    font-family: 'Consolas', 'Courier New', Courier, monospace;
    min-height: 100vh;
    height: auto;
    width: 100%;
    box-sizing: border-box;
    overflow-y: auto; /* This should enable vertical scrolling */
    text-align: left;
    line-height: 1.5;
    font-size: 16px;
    max-height: 100vh; /* Ensure terminal cannot grow beyond viewport height */
    overflow-y: auto;
}
  
  /* Ensure content is readable and accessible on smaller screens */
  @media (max-width: 600px) {
    .terminal {
      font-size: 14px; /* Slightly smaller font size for very small devices */
      padding: 5px; /* Less padding to maximize screen real estate */
    }
  
    .prefix, .terminal-input {
      font-size: 14px; /* Keep input font size consistent with the terminal font size */
    }
  }
  
  .history {
    list-style: none;
    padding: 0;
    margin: 0;
    white-space: pre-wrap;
    word-wrap: break-word;
  }
  
  .input-line {
    display: flex;
    align-items: center;
    justify-content: start;
  }
  
  .prefix {
    user-select: none;
    color: #87d787;
  }
  
  .pre-wrap {
    white-space: pre-wrap;
  }

  .terminal-input {
    background-color: transparent;
    border: none;
    color: inherit;
    outline: none;
    font-family: inherit;
    font-size: inherit;
    padding: 0;
    margin-left: 5px;
    width: -webkit-fill-available;
    box-sizing: border-box;
  }
  